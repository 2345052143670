<template>
  <div class="containerx ma-6">
    <v-row>
      <v-col cols="12" class="pa-0">
        <div
          class="d-flex flex-column flex-grow-1 justify-space-between  card flex-wrap"
        >
          <h3 class=" font font-weight-medium font-size-md text-uppercase">
            Current stock levels of products/fuels
          </h3>
          <p class=" font blue-grey--text font-size-sm">
            Overview of the current stock levels for products/fuels
          </p>
          <div class="d-flex flex-row flex-grow-1">
            <div class="d-flex flex-row flex-grow-1" style="flex-basis: 50%">
              <apexcharts
                class="d-flex box-shadow-light"
                style="width: 100%"
                height="400"
                :options="{
                  ...chartOptions,
                  xaxis: {
                    type: 'Products/Fuels Names',
                    categories: productLevels.labels
                  }
                }"
                :series="productLevels.data"
              ></apexcharts>
            </div>
            <div class="d-flex flex-row flex-grow-1" style="flex-basis: 40%">
              <apexcharts
                class="d-flex box-shadow-light"
                height="400"
                style="width: 100%;"
                :options="{
                  ...chartOptions,
                  chart: {
                    type: 'area'
                  },
                  colors: ['#e59894'],
                  xaxis: {
                    type: 'Products/Fuels Names',
                    categories: productLevels.labels
                  }
                }"
                :series="productLevels.data"
              ></apexcharts>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-grow-1 justify-space-between mt-3 card flex-wrap mt-4"
        >
          <h3 class=" font font-weight-medium font-size-md text-uppercase">
            Current stock levels of Lubes
          </h3>
          <p class=" font blue-grey--text font-size-sm">
            Overview of the current stock levels for lubes
          </p>
          <apexcharts
            class="d-flex box-shadow-light"
            height="550"
            :options="{
              ...chartOptions,
              chart: {
                height: 550,
                type: 'bar'
              },
              colors: ['#e59894'],
              legend: {
                position: 'bottom'
              },
              plotOptions: {
                borderRadius: 50
              },
              xaxis: {
                type: 'Lube Names',
                categories: lubeLevels.labels,
                label: {
                  style: {
                    fontWeight: 'bold',
                    colors: ['black']
                  }
                },
                tooltip: {
                  enabled: true,
                  style: {
                    fontSize: 15
                  }
                }
              }
            }"
            :series="lubeLevels.data"
          ></apexcharts>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex flex-row flex-grow-1"></div>
          <div class="d-flex flex-row flex-grow-1"></div>
        </div>
      </v-col>
      <v-col cols="12">
        d
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "StockLevelsOverview",
  data() {
    return {
      menu: false,
      dates: [],
      items: ["Sales Overview", "Deliveries"],
      durations: [
        { text: "1 week", number: 1, type: "week" },
        { text: "2 weeks", number: 2, type: "weeks" },
        { text: "3 weeks", number: 3, type: "weeks" },
        { text: "1 Month", number: 1, type: "month" }
      ],
      selectedDuration: 0,
      chartOptions: {
        chart: {
          height: 350,
          type: "bar"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type: "Products/Fuels Names",
          categories: []
        }
        // tooltip: {
        //   x: {
        //     format: "dd/MM/yy HH:mm"
        //   }
        // }
      }
    };
  },
  computed: {
    ...mapGetters({
      productLevels: "stocklevels/getStocksAvailableForProducts",
      lubeLevels: "stocklevels/getStocksAvailableForLubes"
    })
  },
  methods: {
    filteredByDates() {}
  },
  created() {
    this.$store.dispatch("stocklevels/getStockAvailable");
  }
};
</script>
