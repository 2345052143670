var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"containerx ma-6"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 justify-space-between  card flex-wrap"},[_c('h3',{staticClass:" font font-weight-medium font-size-md text-uppercase"},[_vm._v(" Current stock levels of products/fuels ")]),_c('p',{staticClass:" font blue-grey--text font-size-sm"},[_vm._v(" Overview of the current stock levels for products/fuels ")]),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"flex-basis":"50%"}},[_c('apexcharts',{staticClass:"d-flex box-shadow-light",staticStyle:{"width":"100%"},attrs:{"height":"400","options":Object.assign({}, _vm.chartOptions,
                {xaxis: {
                  type: 'Products/Fuels Names',
                  categories: _vm.productLevels.labels
                }}),"series":_vm.productLevels.data}})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1",staticStyle:{"flex-basis":"40%"}},[_c('apexcharts',{staticClass:"d-flex box-shadow-light",staticStyle:{"width":"100%"},attrs:{"height":"400","options":Object.assign({}, _vm.chartOptions,
                {chart: {
                  type: 'area'
                },
                colors: ['#e59894'],
                xaxis: {
                  type: 'Products/Fuels Names',
                  categories: _vm.productLevels.labels
                }}),"series":_vm.productLevels.data}})],1)])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 justify-space-between mt-3 card flex-wrap mt-4"},[_c('h3',{staticClass:" font font-weight-medium font-size-md text-uppercase"},[_vm._v(" Current stock levels of Lubes ")]),_c('p',{staticClass:" font blue-grey--text font-size-sm"},[_vm._v(" Overview of the current stock levels for lubes ")]),_c('apexcharts',{staticClass:"d-flex box-shadow-light",attrs:{"height":"550","options":Object.assign({}, _vm.chartOptions,
            {chart: {
              height: 550,
              type: 'bar'
            },
            colors: ['#e59894'],
            legend: {
              position: 'bottom'
            },
            plotOptions: {
              borderRadius: 50
            },
            xaxis: {
              type: 'Lube Names',
              categories: _vm.lubeLevels.labels,
              label: {
                style: {
                  fontWeight: 'bold',
                  colors: ['black']
                }
              },
              tooltip: {
                enabled: true,
                style: {
                  fontSize: 15
                }
              }
            }}),"series":_vm.lubeLevels.data}})],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"}),_c('div',{staticClass:"d-flex flex-row flex-grow-1"})])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" d ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }